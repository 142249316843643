import Head from 'next/head';
import { useEffect, useState, useRef } from 'react';
import { fetchApi } from 'lib/api';
import Image from 'next/image';
import Link from 'next/link';
import Avatar from 'components/Avatar';

export default function Home() {
	const titleListRef = useRef(null);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [statusMsg, setStatusMsg] = useState('');
	const [input, setInput] = useState({});

	const handleInput = ({ currentTarget: { id, value }}) => {
		setInput(prev => ({
			...prev,
			[id]: value
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { email, comments } = e.target;
		const body = {
			email: email.value,
			phone: '604-555-5555',
			subject: 'Contact to hire',
			message: comments.value
		}
		try {
			const results = await fetchApi({ method: 'POST', path: '/contact/sendEmail', body });
			if (results.error) {
				throw new Error();
			}
			e.target.reset();
			setInput({});
			setStatusMsg('Successfully submitted message!');
		} catch (err) {
			setStatusMsg('Failed to submit message!');
		}
		return;
	}

	const jobTitles = [
		'Web Developer',
		'Full Stack Developer',
		'Software Engineer',
		'A/B Testing Specialist',
		'Conversion Rate Optimization Analyst',
		'Experimental Testing Engineer',
		'Web Optimization Strategist',
		'A/B Test Developer',
		'Conversion Enhancement Specialist',
		'Website Experimentation Engineer',
		'User Experience Testing Specialist',
		'Conversion Rate Analyst',
		'JavaScript Testing Architect',
		'Digital Optimization Scientist',
		'User Behavior Analyst',
		'Website Conversion Scientist',
		'Split Testing Engineer',
		'UX A/B Test Strategist',
		'Web Analytics and Testing Specialist',
		'Conversion Rate Enhancement Engineer',
		'A/B Testing and Optimization Expert',
		'JavaScript Experimentation Specialist',
		'User-Centric Testing Engineer'
	]

	useEffect(() => {
		const listItems = titleListRef.current.querySelectorAll('li');
		listItems[currentIndex].setAttribute('aria-label', 'rotate');
		setTimeout(() => {
			listItems[currentIndex].removeAttribute('aria-label');
			setCurrentIndex((currentIndex + 1) % jobTitles.length);
		}, 3400)
		
  	}, [currentIndex])

	return (
		<>
			<Head>
				<title>
					Cheng Pham | Web Developer
				</title>
				<meta name="description" content="As a seasoned full-stack web developer with years of experience, I specialize in building scalable web applications using a range of technologies, including JavaScript, React, Ruby on Rails, and more. Browse my portfolio to see some of my latest projects and get in touch to discuss your next web development project." />
			</Head>
			<header className='hero-avatar-right'>
				<div>
					<div>
						<Avatar />
					</div>
					<div>
						{/* <RichTextRenderer data={ heroSection.heroText } /> */}
						<h1>
							<span>Hi, my <br /> name is</span>
							<span> </span>
							<strong> Cheng</strong>.
						</h1>
						<div>
							<span>I'm a </span>
							<ul ref={ titleListRef }>
								{ jobTitles.length && jobTitles.map((title, idx) => (
									<li key={ idx }>{ title }</li>
								)) }
							</ul>
							<span>from Vancouver, Canada</span>
						</div>
						<div>
							<Link href='mailto:info@chengpham.com' className='btn-cta'>Hire Me Today</Link>
						</div>
					</div>
				</div>
			</header>
			<div className='main-content'>
				<section className='content-left'>
					<h1>Let's work together.</h1>
					<article>
						<div >
						</div>
						<div>
							<p>
								As an AWS cloud developer and a skilled web developer with expertise in JavaScript, React, HTML, CSS, A/B testing, and more, I'm here to help you harness the full potential of cloud computing. Whether it's building robust web applications, creating dynamic front-end experiences, or architecting serverless solutions, I've got you covered.
							</p>
							<div>
								<Link href="mailto:info@chengpham.com">Learn More</Link>
							</div>
						</div>
					</article>
				</section>
				<section className='content-right'>
					<h1>Let's Embark on a Journey of Innovation.</h1>
					<article>
						<div >
						</div>
						<div>
							<p>
								Welcome to my corner of the digital realm. I am a web developer with a passion for pioneering excellence in the world of cloud computing, and I invite you to join me on this exciting journey. With a dynamic background that spans the evolution of web development, I bring a unique blend of experience and ingenuity to the table.
							</p>
							<ul>
								<li>
									<strong>
										Crafting Code, Cultivating Vision
									</strong>
										My story begins as a web developer who cut his teeth in the web development landscape with PHP, WordPress, Dreamweaver, and even dabbled in the intricacies of Flash. This foundation ignited my fascination with the ever-evolving technology landscape. Fast forward a few years, and my path led me into the world of telecommunications and systems administration, fortified by my A+ certification. My journey was far from stagnant, as I continued to tinker with servers, including the likes of Raspberry Pi, which only deepened my technical prowess.
								</li>
								<li>
									<strong>
										A Rekindled Flame
									</strong>
										The allure of web development, however, proved to be a siren song, and I found my way back to it with renewed vigor. This time around, my focus crystallized on JavaScript and React, the powerhouses of modern web development. Through intricate code and striking design, I create compelling user experiences that transcend the mundane and push the boundaries of possibility.
								</li>
								<li>
									<strong>
										Cloud Mastery
									</strong>
										As an AWS Certified Cloud Developer, I have elevated my expertise into the cloud, harnessing its infinite potential to architect serverless solutions and craft robust web applications. I am well-versed in the intricacies of AWS services, ensuring your projects operate at peak performance, scalability, and security. Whether you seek cloud-native applications or the seamless integration of cloud technology into your existing infrastructure, I'm your trusted guide.
								</li>
								<li>
									<strong>
										The Motivation of Challenges
									</strong>
										I thrive on challenges, and it is this unyielding drive that fuels my insatiable appetite for learning and innovation. The dynamic and ever-evolving nature of web development keeps me on my toes, and I revel in adapting to new technologies and staying at the forefront of this exhilarating industry.
								</li>
								<li>
									<strong>
										Join the Journey
									</strong>
										If you share a passion for pushing the boundaries of what is possible in the digital world, I invite you to join me on this exciting journey. Let's collaborate to create web experiences that captivate, solutions that transcend, and innovations that inspire.
								</li>
								<li>
									<strong>
										Explore the Possibilities
									</strong>
										As we embark on this adventure, let's work together to explore the endless possibilities that lie ahead. The world of web development is ever-evolving, and the future is bright. I look forward to the opportunity to contribute my expertise and passion to your projects.
								</li>
							</ul>
							<p>
								Are you ready to embark on this journey together?
							</p>
							<div>
								<Link href="mailto:info@chengpham.com?subject=I'd like to hire you">Learn More</Link>
							</div>
						</div>
					</article>
				</section>
				<section className='content-left'>
					<h1>Reach Out to Start a Conversation</h1>
					<article>
						<div >
						</div>
						<div>
							<p>
								I'm eager to connect with you and discuss how we can collaborate on your next project. Whether you have questions, ideas, or simply want to explore the possibilities, I'm just a message away. Feel free to get in touch, and let's turn your visions into digital reality.
							</p>
							<div>
								<form className='contact-form' onSubmit={ handleSubmit }>
									<span>
										<label htmlFor='email'>Email:</label>
										<input type='text' id='email' name='email' minLength='2' maxLength='100' onChange={ handleInput } required />
									</span>
									<textarea id='comments' name='comments' placeholder='Comments' rows='10'  minLength='2' maxLength='500'></textarea>
									<button type='submit' className='btn-primary'>Submit</button>
									{ statusMsg ? ( <p>{ statusMsg }</p> ) : null }
								</form>
							</div>
						</div>
					</article>
				</section>
			</div>
		</>
	)
}
