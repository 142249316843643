
export const fetchCms = async (query) => {
  try {
    return await (await fetch(process.env.NEXT_PUBLIC_CMS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ process.env.NEXT_PUBLIC_CMS_TOKEN }`
      },
      body: JSON.stringify({ query })
    })).json();
  }
  catch (err) {
    return;
  }
};

export const fetchApi = async ({ method, path, body }) => {
  const apiUrl = process.env.NEXT_PUBLIC_NODE_ENV !== 'development' 
    ? process.env.NEXT_PUBLIC_API_URL_LIVE 
    : process.env.NEXT_PUBLIC_API_URL_LOCAL;
  try {
    return await (await fetch(`${ apiUrl }${ path }`, {
      method: method,
      body: JSON.stringify(body)
    })).json();
  } catch (err) {
    return;
  }
}
